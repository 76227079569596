import { useContext, FC } from 'react';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { AuthPage } from '../modules/auth/AuthPage';
import { App } from '../App';
import AuthContext from '../modules/auth/core/AuthContext';
import { LayoutSplashScreen } from '../../_metronic/layout/core';

const AppRoutes: FC = () => {
  const { user } = useContext(AuthContext);
  const isUserAuthenticated = user !== null;

  console.log("User authenticated:", isUserAuthenticated);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL || '/'}>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          {isUserAuthenticated ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
