// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {
      getAuth,
      signInWithEmailAndPassword,
      signOut
    } from "firebase/auth";
import { getFirestore,
         getDocs, 
         collection,
         doc, 
         setDoc, 
         deleteDoc,
         updateDoc} from "firebase/firestore";



// Your web app's Firebase configuration
const firebaseConfig = {
  // eslint-disable-next-line no-undef
  apiKey: process.env.REACT_APP_FIREBASE_API,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);


export const getPositions = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "warehouse-positions"));
      const positions = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.info(doc.id, " => ", doc.data());
        var data = doc.data();
        data.id = doc.id;
        positions.push(data);
      });
      return positions
    } catch (error) {
      return {error: error.message}
    }
  };

export const addPallet = async (data, callback) => {
      try {
        await setDoc(doc(db, "incoming-pallets", data.id), data);
      if (callback && typeof callback === 'function') {
        callback(); // Call the callback function (handlePrint) if provided
      }
        return true
      } catch (error) {
        return {error: error.message}
      }
    };
export const deletePallet = async (id) => {
    try {
      await deleteDoc(doc(db, "incoming-pallets", id));
      return true
    } catch (error) {
      return {error: error.message}
    }
};
export const updatePositionStatus = async (id,statusNumber) => {
    try {
      const position = doc(db, "warehouse-positions", id);

    // Set the "capital" field of the city 'DC'
    await updateDoc(position, {
      status: statusNumber
    });

      return true
    } catch (error) {
      return {error: error.message}
    }
};

export const getIncomingPallets = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "incoming-pallets"));
    const pallets = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.info(doc.id, " => ", doc.data());
        var data = doc.data();
        data.id = doc.id;
        pallets.push(data);
      });
      return pallets
    } catch (error) {
      return {error: error.message}
    }
  };

  export const updatePallet = async (id,updatedData, callback) => {
      try {
        const pallet = doc(db, "incoming-pallets", id);
  
      // Set the "capital" field of the city 'DC'
      await updateDoc(pallet, updatedData);
      if (callback && typeof callback === 'function') {
        callback(); // Call the callback function (handlePrint) if provided
      }
        return true
      } catch (error) {
        return {error: error.message}
      }
  };

  export const getParts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "parts"));
      const parts = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.info(doc.id, " => ", doc.data());
        var data = doc.data();
        data.id = doc.id;
        //console.info(doc.data());
        parts.push(data);
      });
      return parts
      } catch (error) {
        return {error: error.message}
      }
    };

    export const addPart = async (data) => {
        try {
          await setDoc(doc(db, "parts", data.id), data);
          return true
        } catch (error) {
          return {error: error.message}
        }
      };
  export const deletePart = async (id) => {
      try {
        await deleteDoc(doc(db, "parts", id));
        return true
      } catch (error) {
        return {error: error.message}
      }
  };

export const signIn = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user; 
      return true
    } catch (error) {
      return {error: error.message}
    }
  };

  export const signTotallyOut = async() => {
      try {
        await signOut(auth)
        return true
      } catch (error) {
      console.info(error);
        return false
      }
    };