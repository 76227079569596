import React, { useEffect, useState } from 'react';
import { getParts, getIncomingPallets } from '../../modules/auth/firebase';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import { KTCard, KTCardBody, KTIcon } from '../../../_metronic/helpers';
import { PageTitle } from '../../../_metronic/layout/core';
import { flexRender, useReactTable, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel  } from "@tanstack/react-table";
import { addPart, deletePart } from '../../modules/auth/firebase';

const PartsPage = () => {
    const [part, setPart] = useState({
        article_number: '',
        name: '',
        family: ''
    });
    const families = [
        { value: 'Noblès', label: 'Noblès' },
        { value: 'Grande Noblès', label: 'Grande Noblès' },
        { value: 'Eclips', label: 'Eclips' },
        { value: 'Ecosy', label: 'Ecosy' },
        { value: 'Massiv', label: 'Massiv' },
        { value: 'Max', label: 'Max' },
        { value: 'Torus', label: 'Torus' },
        { value: 'Vision', label: 'Vision' },
        { value: 'Others', label: 'Others' },
    ];
    const [parts, setParts] = useState([]);
    const [pallets, setPallets] = useState([]);
    const [filtering, setFiltering] = useState('');
    const [sorting, setSorting] = useState([]);

    const [addModalVisible, setAddModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const [selectedPart, setSelectedPart] = useState('');

    const openAddModal = () => {
        setAddModalVisible(true);
    };

    const closeAddModal = () => {
        setAddModalVisible(false);
    };
    const openDeleteModal = () => {
        setDeleteModalVisible(true);
      };

    const closeDeleteModal = () => {
        setDeleteModalVisible(false);
      };

    const handleAdd = (event) => {
        // prevents the submit button from refreshing the page
        event.preventDefault();

        if (!part.article_number || !part.name || !part.family) {
            // Display an alert or handle the error appropriately
            alert("Please fill in all required fields.");
            return;
        }

        const newPart = {
            ...part,
            id: parseInt(Date.now() * Math.random()).toString(),
        }

        setParts((parts) => [...parts, newPart]);
        addPart(newPart); 
        closeAddModal();
        setPart({
            article_number: '',
            name: '',
            family: ''
        }); 
    };

    const handleDelete = (event) => {

        const filteredArray = parts.filter(item => item.id !== selectedPart);
        setParts(filteredArray);
        deletePart(selectedPart);
        setDeleteModalVisible(false);
        setPart({
            article_number: '',
            name: '',
            family: ''
        }); 
    
      };

      const focusFirstInput = () => {
        const firstInput = document.querySelector('.modal-body input[type="text"], .modal-body select');
        if (firstInput) {
            firstInput.focus();
        }
    };


    useEffect(() => {
        Promise.all([getIncomingPallets(), getParts()])
            .then(([pallets, parts]) => {
                // Calculate sum of quantities for each part and add it to the part object
                const partsWithQuantities = parts.map(part => {
                    const quantity = pallets.reduce((total, pallet) => {
                        if (pallet.part.id === part.id) {
                            return total + parseInt(pallet.quantity, 10);
                        }
                        return total;
                    }, 0);
                    return { ...part, quantity };
                });
                setPallets(pallets);
                setParts(partsWithQuantities);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    const columnDef = [
        {
            accessorKey: "article_number",
            header: "Article number",
        },
        {
            accessorKey: "name",
            header: "Name",
        },
        {
            accessorKey: "family",
            header: "Family",
        },
        {
            accessorKey: "quantity", // Accessor key for the sum of quantities property
            header: "Est. Quantity",
        },
        {
          accessorKey: "positions",
          header: "Positions",
          size: 250,
          cell: ({ row }) => {
            const partId = row.original.id;
            const sumPositions = pallets.reduce((total, pallet) => {
                if (pallet.part.id === partId) {
                    if (total === '') {
                        // If total is an empty string, don't append ', ' to the beginning
                        return pallet.warehouse_position.line + pallet.warehouse_position.position + '-' + pallet.warehouse_position.level;
                    } else {
                        // If total is not an empty string, append ', ' to separate positions
                        return total + ', ' + pallet.warehouse_position.line + pallet.warehouse_position.position + '-' + pallet.warehouse_position.level;
                    }
                }
                return total;
            }, ''); // Initial value set to an empty string
            return sumPositions;
        },
        },
        {
            accessorKey: "actions",
            header: "Actions",
            size: 50,
            cell: ({ row }) => {
                if (row.original.quantity === 0) {
                    return (
                      <div className="d-flex flex-shrink-0">
                        <a
                          onClick={() => {
                            openDeleteModal();
                            setSelectedPart(row.original.id);
                          }}
                          className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                        >
                          <i className="ki-duotone ki-trash fs-3">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                            <span className="path4"></span>
                            <span className="path5"></span>
                          </i>
                        </a>
                      </div>
                    );
                  } else {
                    return null; // Render nothing if quantity is not 0
                  }
            }
          },
    ];

    const tableInstance = useReactTable({
        columns: React.useMemo(() => columnDef, [columnDef]),
        data: React.useMemo(() => parts, [parts]),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        state: {
            globalFilter: filtering,
            sorting: sorting
        },
        onSortingChange: setSorting,
        onGlobalFilterChanged: setFiltering,
    });

    return (
        <>
            <PageTitle>Parts</PageTitle>
            <KTCard>
                <div className='card-header border-0 pt-6'>
                    <div className='card-title'>
                        <div className='d-flex align-items-center position-relative my-1'>
                            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-3' />
                            <input
                                type='text'
                                className='form-control form-control-solid w-250px ps-12'
                                placeholder='Search'
                                value={filtering}
                                onChange={(e) => setFiltering(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='card-toolbar'>
                        {/* begin::Add user */}
                        <button type='button' className='btn btn-success' onClick={openAddModal}>
                        add part
                        </button>
                        {/* end::Add user */}
                    </div>
                </div>
                <KTCardBody>
                    <table className="table align-middle table-row-dashed table-row-gray-300 gy-5">
                        <thead>
                            {tableInstance.getHeaderGroups().map(headerEl => {
                                return <tr className="fw-bolder fs-6" key={headerEl.id}>
                                    {headerEl.headers.map(columnEl => {
                                        return <th key={columnEl.id} onClick={columnEl.column.getToggleSortingHandler()}>
                                            {flexRender(
                                                columnEl.column.columnDef.header,
                                                columnEl.getContext()
                                            )}
                                            {
                                            {asc: ' 🔼',
                                            desc: ' 🔽',}[
                                                columnEl.column.getIsSorted() ?? null
                                            ]
                                            } 
                                        </th>
                                    })}
                                </tr>
                            })}
                        </thead>
                        <tbody>
                            {tableInstance.getRowModel().rows.map(rowEl => {
                                return <tr key={rowEl.id}>
                                    {rowEl.getVisibleCells().map(cellEl => {
                                        return <td key={cellEl.id} style={{
                                            width: cellEl.column.getSize(),
                                          }}>
                                            {flexRender(
                                                cellEl.column.columnDef.cell,
                                                cellEl.getContext()
                                            )}
                                        </td>
                                    })}
                                </tr>
                            })}
                        </tbody>
                    </table>
                    <ul className="pagination flex-start">
                        <li className={`page-item first m-1 ${!tableInstance.getCanPreviousPage() ? 'disabled' : ''}`}>
                            <button className="page-link px-0" disabled={!tableInstance.getCanPreviousPage()} onClick={() => tableInstance.setPageIndex(0)}>
                                <i className="ki-duotone ki-double-left fs-2"><span className="path1"></span><span className="path2"></span></i>
                            </button>
                        </li>
                        <li className={`page-item previous m-1 ${!tableInstance.getCanPreviousPage() ? 'disabled' : ''}`}>
                            <button className="page-link px-0" onClick={() => tableInstance.previousPage()} disabled={!tableInstance.getCanPreviousPage()} >
                                <i className="ki-duotone ki-left fs-2"></i>
                            </button>
                        </li>
                        <li className={`page-item next m-1 ${!tableInstance.getCanNextPage() ? 'disabled' : ''}`}>
                            <button className="page-link px-0" disabled={!tableInstance.getCanNextPage()} onClick={() => tableInstance.nextPage()}>
                                <i className="ki-duotone ki-right fs-2"></i>
                            </button>
                        </li>
                        <li className={`page-item last m-1 ${!tableInstance.getCanNextPage() ? 'disabled' : ''}`}>
                            <button className="page-link px-0" disabled={!tableInstance.getCanNextPage()} onClick={() => tableInstance.setPageIndex(tableInstance.getPageCount() - 1)}>
                                <i className="ki-duotone ki-double-right fs-2"><span className="path1"></span><span className="path2"></span></i>
                            </button>
                        </li>
                    </ul>
                </KTCardBody>
            </KTCard>

            <Modal show={addModalVisible} onHide={closeAddModal} onShow={focusFirstInput}>
                <Modal.Header closeButton>
                <Modal.Title>Add part</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    <div className="mb-8">
                    <label className="form-label">Article number</label>
                    <input
                        type="text"
                        className="form-control"
                        value={part.article_number} onChange={(event) =>
                            setPart({...part, article_number: event.target.value})
                        }
                    />
                    </div>
                    <div className="mb-8">
                    <label className="form-label">Name</label>
                    <input
                        type="text"
                        className="form-control"
                        value={part.name} onChange={(event) =>
                        setPart({...part, name: event.target.value})
                        }
                    />
                    </div>
                    <div className="mb-2">
                        <label className="form-label">Family</label>
                        <Select
                            options={families}
                            onChange={selectedOption => {
                                setPart({...part, family: selectedOption.value});
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                    type="button"
                    className="btn btn-light"
                    onClick={closeAddModal}
                    >
                    cancel
                    </button>
                    <button type="button" onClick={handleAdd} className="btn btn-success">
                    add part
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={deleteModalVisible} onHide={closeDeleteModal}>
                <Modal.Header closeButton>
                <Modal.Title>Delete part</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Are you sure you want to delete this item?
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-light"
                        onClick={closeDeleteModal}
                    >
                        cancel
                    </button>
                    <button type="button" className="btn btn-danger" onClick={handleDelete}>
                        delete part
                    </button>
                </Modal.Footer>
                
            </Modal>
        </>
    );
}

export default PartsPage;
