import { getAuth } from "firebase/auth";
import { useState, useEffect } from "react";
import AuthContext from "./AuthContext";

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);

  console.log("hello");

  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged((user) => {
      console.log("Auth state changed:", user);
      setUser(user);
    }, (error) => {
      console.error("Auth state error:", error);
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
}
