/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItemWithSub
        to='/warehouse'
        title='Warehouse'
        fontIcon='bi-bookshelf'
      >
        <SidebarMenuItem to='/warehouse/shelves' title='Shelves' hasBullet={true} />
        <SidebarMenuItem to='/warehouse/incoming-pallets' title='Pallets' hasBullet={true} />
      </SidebarMenuItemWithSub>
      
      <SidebarMenuItem to='/parts'
        title='Parts'
        fontIcon='bi-puzzle' />
  
    </>
  )
}

export {SidebarMenuMain}
